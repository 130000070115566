var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(Object.entries(_vm.data).length === 0 ? _vm.$t('Add New') : _vm.$t('Update'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Currency Unit'),"label-for":"currencyUnit"}},[_c('validation-provider',{attrs:{"name":"CurrencyUnit","vid":"currencyUnit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"currencyUnit","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Currency Unit')},model:{value:(_vm.currencyUnit),callback:function ($$v) {_vm.currencyUnit=$$v},expression:"currencyUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Equal to the default currency') + '(THB)',"label-for":"eqToDefaultCurrency"}},[_c('validation-provider',{attrs:{"name":"EqToDefaultCurrency","vid":"eqToDefaultCurrency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"eqToDefaultCurrency","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Equal to the default currency') + '(THB)'},model:{value:(_vm.eqToDefaultCurrency),callback:function ($$v) {_vm.eqToDefaultCurrency=$$v},expression:"eqToDefaultCurrency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Currency Symbol'),"label-for":"register-currencySymbol"}},[_c('validation-provider',{attrs:{"name":"currencySymbol","vid":"currencySymbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-currencySymbol","name":"register-currencySymbol","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Currency Symbol')},model:{value:(_vm.currencySymbol),callback:function ($$v) {_vm.currencySymbol=$$v},expression:"currencySymbol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }